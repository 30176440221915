#main_ListaServico{
  display: flex;  
  flex-grow: 1;
  flex-direction: column;
}

#cabecalho_ListaServico{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

#cabecalhoMenu_ListaServico{
  display: flex;
  flex-grow: 0;
}

#cabecalhoTitulo_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 2px;
  justify-content: center;
}

#titulo_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 300;  
  color: rgb(2, 155, 222);  
  margin-top: 6px;
  margin-right: 3px;  
}

#tituloSub_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 20px;
  font-weight: 700;
  color: rgb(195, 214, 0);
}

#cabecalhoLogo_ListaServico{
  display: flex;
  flex-grow: 0;
}

/* 1 - Categoria */
.categoria_main_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#categoria_carregando_ListaServico{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#categoria_carregandoTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#categoria_filtroRecolhido_ListaServico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#categoria_filtroRecolhidoFiltro_ListaServico{
  display: flex;
  flex-grow: 0;
  width: 35px;
}

#categoria_filtroRecolhidoFiltroImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#categoria_filtroRecolhidoDescricao_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#categoria_filtroRecolhidoDescricaoImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#categoria_filtroRecolhidoDescricaoTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#categoria_filtro_ListaServico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#categoria_filtroFiltro_ListaServico{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;
}

#categoria_filtroFiltroImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#categoria_filtroCampos_ListaServico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#categoria_filtroCampo_ListaServico{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

#categoria_filtroRecolher_ListaServico{
  margin-bottom: 10px;
}

#categoria_filtroRecolherView_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#categoria_filtroRecolherImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#categoria_filtroRecolherTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#categoria_descricaoServico_ListaServico{
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

#categoria_titulo_ListaServico{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#categoria_categorias_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

#categoria_dados_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

#catetoria_card_ListaServico{
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

#categoria_dadosSem_ListaServico{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 264px;
}

#categoria_dadosSemTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 600;
  color: rgb(195, 214, 0);
  text-align: center;
}

#categoria_mensagem_ListaServico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#categoria_mensagemTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#categoria_mensagemErro_ListaServico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#categoria_mensagemErroTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

/* 2 - Sub-Categoria */
.subCategoria_main_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#subCategoria_carregando_ListaServico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;  
}

#subCategoria_carregandoTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_filtroRecolhido_ListaServico{
  display: flex;
  flex-grow: 0;  
  flex-direction: row;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#subCategoria_filtroRecolhidoFiltro_ListaServico{
  display: flex;
  flex-grow: 0;  
}

#subCategoria_filtroRecolhidoFiltroImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#subCategoria_filtroRecolhidoDescricao_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#subCategoria_filtroRecolhidoDescricaoImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#subCategoria_filtroRecolhidoDescricaoTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#subCategoria_filtro_ListaServico{
  display: flex;
  flex-grow: 0;  
  flex-direction: row;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#subCategoria_filtroFiltro_ListaServico{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;
}

#subCategoria_filtroFiltroImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#subCategoria_filtroCampos_ListaServico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#subCategoria_filtroCampo_ListaServico{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

#subCategoria_filtroRecolher_ListaServico{
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_filtroRecolherView_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#subCategoria_filtroRecolherImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#subCategoria_filtroRecolherTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#subCategoria_titulo_ListaServico{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_dados_ListaServico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

#subCategoria_dadosSem_ListaServico{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-height: 264px;
}

#subCategoria_dadosSemTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 600;
  color: rgb(195, 214, 0);
  text-align: center;
}

#subCategoria_mensagem_ListaServico{
  display: flex;
  flex-grow: 0;  
  justify-content: center;
  align-items: center;
}

#subCategoria_mensagemTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_mensagemErro_ListaServico{
  display: flex;
  flex-grow: 0;  
  justify-content: center;
  align-items: center;
}

#subCategoria_mensagemErroTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

/* 3 - Prestadores */
.serviceProvider_main_ListService{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#serviceProvider_loading_ListService{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#serviceProvider_textLoading_ListService{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#serviceProvider_extendFilter_ListService{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#serviceProvider_extendFilterFilter_ListService{
  display: flex;
  flex-grow: 0;
}

#serviceProvider_imageExtendFilterFilter_ListService{
  width: 24px;
  height: 24px;
}

#serviceProvider_descriptionExtendFilter_ListService{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#serviceProvider_imageDescriptionExtendFilter_ListService{
  width: 24px;
  height: 24px;
}

#serviceProvider_textDescriptionExtendFilter_ListService{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#serviceProvider_filter_ListService{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#serviceProvider_filterFilter_ListService{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;
}

#serviceProvider_imageFilterFilter_ListService{
  width: 24px;
  height: 24px;
}

.serviceProvider_containerFieldFilter_ListService{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 10px;
}

.serviceProvider_FieldFilterDouble_ListService{
  display: flex;
  flex-grow: 1;
}

#serviceProvider_retractFilter_ListService{
  margin-top: 10px;
  margin-bottom: 10px;
}

#serviceProvider_viewRetractFilter_ListService{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#serviceProvider_imageRetractFilter_ListService{
  width: 24px;
  height: 24px;
}

#serviceProvider_textRetractFilter_ListService{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#serviceProvider_serviceDescription_ListaServico{
  margin-left: 10px;
  margin-top: 10px;
}

#serviceProvider_labelServiceDescription_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  margin-right: 5px;
}







#serviceProvider_pagination_ListService{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

#serviceProvider_viewPagination_ListService{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 150px;
}

.serviceProvider_buttonPagination_ListService{
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.serviceProvider_buttonImagePagination_ListService{
  width: 24px;
  height: 24px;
}

#serviceProvider_textPagination_ListService{
  font-family: "Roboto-Regular";
  font-size: 14;
}

#serviceProvider_container_ListService{
  display: flex;
  flex-grow: 0;
  flex-direction: column;
}




/* Screen width between 0 and 699 px */
#serviceProvider_To699_data_ListService{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#serviceProvider_To699_nameServiceProvider_ListService{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-left: 10px;
}

#serviceProvider_To699_textNameServiceProvider_ListService{
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: 600;
  color: rgb(195, 214, 0);
}

#serviceProvider_To699_buttonMoreInformations_ListService{
  margin-left: 10px;
  width: 18px;
  height: 18px;
}

#serviceProvider_To699_textMoreInformations_ListService{
  font-family: "Roboto-Regular";
  font-size: 12px;
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-left: 5px;
  margin-top: 2px;
}

#serviceProvider_To699_otherInformations_ListService{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

#serviceProvider_To699_containerImageWhatsApp_ListService{
  display: flex;
  flex-grow: 0;
  margin-left: 10px;
}

#serviceProvider_To699_imageWhatsApp_ListService{
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

#serviceProvider_To699_containerPhone_ListService{
  display: flex;
  flex-grow: 1;
}

#serviceProvider_To699_phone_ListService{
  font-family: "Roboto-Regular";
  font-size: 12px;
  font-weight: 600;
}

#serviceProvider_To699_containerDistance_ListService{
  display: "flex";
  flex-grow: 0;
  margin-right: 10px;
  align-items: center;
}

#serviceProvider_To699_titleDistance_ListService{
  font-family: "Roboto-Regular";
  font-size: 13px;
  font-weight: 600;
  color: rgb(195, 214, 0);
  text-align: right;
}

#serviceProvider_To699_distance_ListService{
  font-family: "Roboto-Regular";
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  margin-left: 5px;
}

.serviceProvider_To699_service_ListService{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-right: 5px;
  padding-left: 10px;
}

.serviceProvider_To699_containerPhoto_ListService{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(241, 242, 242);
  border-left-width: 7px;
  border-left-color: rgb(195, 214, 0);
  border-left-style: solid;
}

.serviceProvider_To699_containerNoPhoto_ListService{
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.serviceProvider_To699_containerDataService_ListService{
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  background-color: rgb(241, 242, 242);
  border-left-width: 7px;
  border-left-color: rgb(195, 214, 0);
  border-left-style: solid;
  padding: 10px;
}

.serviceProvider_To699_dataServiceRow_ListService{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.serviceProvider_To699_dataServiceFlexGrow1_ListService{
  display: flex;
  flex-grow: 1;
}

.serviceProvider_To699_dataServiceDescription_ListService{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(2, 155, 222);
}

.serviceProvider_To699_dataStar_ListService{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 1;
  flex-basis: 0;
}

.serviceProvider_To699_imageDataStar_ListService{
  width: 14px;
  height: 14px;
}

.serviceProvider_To699_dataValue_ListService{
  font-family: "Roboto-Regular";
  font-size: 14;
  font-weight: 600;
}

.serviceProvider_To699_dataServiceFlexGrow0_ListService{
  display: flex;
  flex-grow: 0;
}

.serviceProvider_To699_dataTotalAssessment_ListService{
  font-family: "Roboto-Regular";
  font-size: 14;
  font-weight: 600;
}

.serviceProvider_To699_dataDescription_ListaServico{
  font-family: "Roboto-Regular"
}
/* /// */

/* Screen width above 699 px */
#serviceProvider_Above699_data_ListService{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 5px;
}

#serviceProvider_Above699_containerDataUser_ListService{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

#serviceProvider_Above699_user_ListService{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-left: 10px;
}

#serviceProvider_Above699_userName_ListService{
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: 600;
  color: rgb(195, 214, 0);
}

#serviceProvider_Above699_imageMoreInformation_ListService{
  margin-left: 10px;
  width: 18px;
  height: 18px;
}

#serviceProvider_Above699_moreInformation_ListService{
  color: rgb(2, 155, 222);
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
  margin-top: 2px;
}

#serviceProvider_Above699_containerDistance_ListService{
  display: "flex";
  flex-grow: 0;
  margin-right: 10px;
  align-items: center;
}

#serviceProvider_Above699_titleDistance_ListService{
  font-family: "Roboto-Regular";
  font-size: 13px;
  font-weight: 600;
  color: rgb(195, 214, 0);
  text-align: right;
}

#serviceProvider_Above699_distance_ListService{
  font-family: "Roboto-Regular";
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  margin-left: 5px;
}

#serviceProvider_Above699_otherInformations_ListService{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
}

#serviceProvider_Above699_containerImageWhatsApp_ListService{
  display: flex;
  flex-grow: 0;
  margin-left: 10px;
}

#serviceProvider_Above699_imageWhatsApp_ListService{
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

#serviceProvider_Above699_containerPhone_ListService{
  display: flex;
  flex-grow: 1;
}

#serviceProvider_Above699_phone_ListService{
  font-family: "Roboto-Regular";
  font-size: 12px;
  font-weight: 600;
}

.serviceProvider_Above699_containerService_ListService{
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.serviceProvider_Above699_service_ListService{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 5px;
  padding-left: 10px;
  background-color: rgb(241, 242, 242);
  border-left-width: 7px;
  border-left-color: rgb(195, 214, 0);
  border-left-style: solid;
}

.serviceProvider_Above699_containerServiceTitle_ListService{
  display: flex;
  flex-direction: row;
}

.serviceProvider_Above699_serviceTitle_ListService{
  display: flex;
  flex-grow: 1;
  margin-bottom: 5px;
}

.serviceProvider_Above699_textServiceTitle_ListService{
  font-family: "Roboto-Regular";
  color: rgb(2, 155, 222);
  font-size: 13px;
  font-weight: 600;
  margin-right: 5px;
}

.serviceProvider_Above699_imageDataStar_ListService{
  width: 14px;
  height: 14px;
}

.serviceProvider_Above699_cotainerValue_ListService{
  display: flex;
  flex-grow: 0;
  justify-content: flex-end;
  min-width: 150px;
  margin-right: 5px;
}

.serviceProvider_Above699_value_ListService{
  font-family: "Roboto-Regular";
  font-size: 13px;
  font-weight: 600;
  color: rgb(195, 214, 0);
  text-align: right;
  margin-right: 5px;
}

.serviceProvider_Above699_textValue_ListService{
  font-family: "Roboto-Regular";
  font-size: 12px;
  font-weight: 600;
}

.serviceProvider_Above699_containerPhoto_ListService{
  display: flex;
  flex-direction: "row";
  max-height: 90px;
}

.serviceProvider_Above699_imageServicePhoto_ListService{
  height: 65px;
  width: auto;
  border-radius: 20%;
}

.serviceProvider_Above699_servicePhotoAfterFirst_ListService{
  margin-left: 5px;
}

.serviceProvider_Above699_servicePhotoLast_ListService{
  margin-left: 5px;
  margin-right: 5px;
}
/* /// */

#serviceProvider_noData_ListService{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 264px;
}

#serviceProvider_textNoData_ListService{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 600;
  color: rgb(195, 214, 0);
  text-align: center;
}

#serviceProvider_menssage_ListService{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#serviceProvider_textMessage_ListService{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#serviceProvider_menssageError_ListService{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#serviceProvider_textMessageError_ListService{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#serviceProvider_button_ListService{
  display: "flex";
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

#serviceProvider_imageButton_ListServic{
  width: 51px;
  height: 51px;
}

@media all 
  and (max-device-width: 280px){

  .serviceProvider_servicePhoto_ListService {
    border-radius: 10px;
    margin: 10px;
    width: auto;
    height: 140px; 
  }

  .serviceProvider_FieldFilter_ListService{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 10px;
  };
}

@media all 
  and (min-device-width: 281px)
  and (max-device-width: 415px){
    
  .serviceProvider_servicePhoto_ListService {
    border-radius: 10px;
    margin: 10px;
    width: auto;
    height: 200px;  
  }

  .serviceProvider_FieldFilter_ListService{
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    margin-top: 10px;
  };
}

@media all
  and (min-device-width: 416px){

  .serviceProvider_servicePhoto_ListService{
    border-radius: 10px;
    margin: 10px;
    width: auto;
    height: 250px;  
  }

  .serviceProvider_FieldFilter_ListService{
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    margin-top: 10px;
  };
}

/* 4 - Dados Prestadores */
.dadosPrestador_main_ListaServico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#dadosPrestador_carregando_ListaServico{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#dadosPrestador_carregandoTexto_ListaServico{
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#dadosPrestador_filtroRecolhido_ListaServico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#dadosPrestador_filtroRecolhidoFiltro_ListaServico{
  display: flex;
  flex-grow: 0;
}

#dadosPrestador_filtroRecolhidoFiltroImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#dadosPrestador_filtroRecolhidoDescricao_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#dadosPrestador_filtroRecolhidoDescricaoImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#dadosPrestador_filtroRecolhidoDescricaoTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#dadosPrestador_filtro_ListaServico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#dadosPrestador_filtroFiltro_ListaServico{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;  
}

#dadosPrestador_filtroFiltroImagem_ListaServico{
  width: 24px;
  height: 24px;
}

.dadosPrestador_filtroCampos_ListaServico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dadosPrestador_filtroCampo_ListaServico{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

#dadosPrestador_filtroRecolher_ListaServico{
  margin-top: 10px;
  margin-bottom: 10px;
}

#dadosPrestador_filtroRecolherView_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#dadosPrestador_filtroRecolherImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#dadosPrestador_filtroRecolherTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#dadosPrestador_usuario_ListaServico{
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  margin-top: 5px;
}

#dadosPrestador_usuarioFoto_ListaServico{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;  
  margin-top: 5px;
}

#dadosPrestador_usuarioFotoImagem_ListaServico{
  border-radius: 10px;
  width: 110px;
  height: auto;
}

#dadosPrestador_usuarioFotoSemImagem_ListaServico{
  border-radius: 50px;
  width: 110px;
  height: auto;
}

#dadosPrestador_dadosInformacao_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

#dadosPrestador_usuarioNome_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  width: 200px;
}

#dadosPrestador_usuarioNomeImagem_ListaServico{
  width: 36px;
  height: auto;
}

#dadosPrestador_usuarioNomeTexto_ListaServico{
  font-family: "Roboto-Regular";
  padding-left: 10px;
}

#dadosPrestador_usuarioEmail_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  width: 200px;
}

#dadosPrestador_usuarioEmailImagem_ListaServico{
  width: 36px;
  height: auto;
}

#dadosPrestador_usuarioEmailTexto_ListaServico{
  font-family: "Roboto-Regular";
  padding-left: 10px;
}

#dadosPrestador_usuarioTelefone_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  width: 200px;
}

#dadosPrestador_usuarioTelefoneImagem_ListaServico{
  width: 36px;
  height: auto;
}

#dadosPrestador_usuarioTelefoneTexto_ListaServico{
  font-family: "Roboto-Regular";
  padding-left: 10px;
}

#dadosPrestador_dados_ListaServico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
}

#dadosPrestador_paginacao_ListaServico{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 5px;
}

#dadosPrestador_paginacaoView_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 150px;
}

.dadosPrestador_paginacaoBotao_ListaServico{
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.dadosPrestador_paginacaoBotaoImagem_ListaServico{
  width: 24px;
  height: 24px;
}

#dadosPrestador_paginacaoTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 14;
}

#dadoPrestador_dados_ListaServico{
  display: flex;
  flex-direction: row;
  margin-Top: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

#dadoPrestador_usuario_ListaServico{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 0;
  background-color: rgb(241, 242, 242);
  border-left-color: rgb(195, 214, 0);
  border-left-style: solid;
  border-left-width: 7px;
}

#dadoPrestador_usuarioView_ListaServico{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#dadoPrestador_usuarioFoto_ListaServico{
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.dadoPrestador_usuarioFotoImagem_ListaServico{
  width: 48px;
  height: auto;
}

#dadoPrestador_usuarioNome_ListaServico{
  justify-content: center;
  padding-left: 8px;
}

#dadoPrestador_usuarioNomeTexto_ListaServico{
  font-family: "Roboto-Regular";
  margin-top: 3px;
  margin-bottom: 5px;
}

#dadosPrestador_servicoPrestado_ListaServico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  background-color: rgb(241, 242, 242);
  padding-left: 15px;
}

#dadosPrestador_estrelas_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
  padding-right: 8px;
}

.dadosPrestador_estrelasImagem_ListaServico{
  width: 16px;
  height: 16px;
}

#dadosPrestador_data_ListaServico{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 8px;
}

#dadosPrestador_dataDecricao_ListaServico{
  font-family: "Roboto-Regular";
}

#dadosPrestador_servico_ListaServico{
  display: "flex";
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  padding-right: 8px;
}

#dadosPrestador_servicoDescricao_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(2, 155, 222);
}

#dadosPrestador_avaliacao_ListaServico{
  display: "flex";
  flex-direction: row;
  justify-content: flex-start;
  padding-right: 5px;
  margin-bottom: 5px;
}

#dadosPrestador_avaliacaoDescricao_ListaServico{
  font-family: "Roboto-Regular"
}

#dadosPrestador_mensagem_ListaServico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#dadosPrestador_mensagemTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#dadosPrestador_mensagemErro_ListaServico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#dadosPrestador_mensagemErroTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10;
  margin-bottom: 10;
}

#dadosPrestador_botao_ListaServico{
  display: "flex";
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

#dadosPrestador_botaoImagem_ListaServico{
  width: 51px;
  height: 51px;
}

@media all 
  and (max-device-width: 359px){

  #imgTitulo_ListaServico {
    width: auto;
    height: 15px;
  };
}

@media all 
  and (min-device-width: 360px){

  #imgTitulo_ListaServico {
    width: auto;
    height: 25px;
  };
}