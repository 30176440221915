.main_servicoFoto{
  display: flex;  
  flex-grow: 1;
  flex-direction: column;
}

#carregando_servicoFoto{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#carregandoTexto_servicoFoto{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#containerTitulo_servicoFoto{
  display: flex;
  flex-direction: row;
  flex-grow: 0;  
  align-items: center;
  justify-content: center;
  margin: 0 20%;
}

#titulo_servicoFoto{
  display: flex;
  flex-grow: 1;
  justify-content: center;  
  border-bottom: solid  rgb(195, 214, 0) 3px;
  padding-bottom: 5px;
}

#tituloTexto_servicoFoto{
  font-family: "Roboto-Regular";
  font-weight: 600;
  font-size: 20px;
  color: rgb(195, 214, 0);  
}

#add_servicoFoto{
  padding-left: 10px;
}

#usuario_servicoFoto{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

#fotos_servicoFoto{
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.containerFoto_servicoFoto{
  margin: 10px;
}

.foto_servicoFoto{
  border-radius: 10%;
  height: 250px;
  width: 250px;
}

.excluir_servicoFoto{
  margin-left: -40px;
  margin-bottom: -200px;
}

#nenhumRegistro_servicoFoto{
  margin-top: 10px;
  margin-bottom: 20px;
}

#nenhumRegistroTexto_servicoFoto{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
}

#mensagem_servicoFoto{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#mensagemTexto_servicoFoto{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#mensagemErro_servicoFoto{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#mensagemErroTexto_servicoFoto{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}