.main_ServicoContratado {
  display: flex;
  flex-grow: 1;
  flex-basis: 1;
  flex-direction: column;
}

#carregando_ServicoContratado{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#carregandoTexto_ServicoContratado{
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#cabecalho_ServicoContratado{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

#cabecalhoMenu_ServicoContratado{
  display: flex;
  flex-grow: 0;
}

#cabecalhoTitulo_ServicoContratado{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 2px;
  justify-content: center;
}

#cabecalhoLogo_ServicoContratado{
  display: flex;
  flex-grow: 0;
}

#bordarBottom_ServicoContratado{
  border-bottom: solid 10px rgb(195, 214, 0);
}

#mensagem_ServicoContratado{
  display: flex;
  flex-grow: 0; 
  justify-content: center;
  align-items: center;

  background-color: brown;
}

#mensagemTexto_ServicoContratado{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#mensagemErro_ServicoContratado{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;  

  background-color: red;
}

#mensagemErroTexto_ServicoContratado{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

.marginTop5_ServicoContratado{
  margin-top: 5px;
}

.marginTop10_ServicoContratado{
  margin-top: 10px;
}

.marginLeft5_ServicoContratado{
  margin-left: 5px;
}

/* ================================= */
/* ===          Contratante      === */
/* ================================= */
#pesquisa_filtroRecolhido_ServicoContratado{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#pesquisa_filtroRecolhidoFiltro_ServicoContratado{
  display: flex;
  flex-grow: 0;
  align-items: center;
}

#pesquisa_filtroRecolhidoFiltroImagem_ServicoContratado{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolhidoDescricao_ServicoContratado{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#pesquisa_filtroRecolhidoDescricaoImagem_ServicoContratado{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolhidoDescricaoTexto_ServicoContratado{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#pesquisa_filtro_ServicoContratado{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#pesquisa_filtroFiltro_ServicoContratado{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;
}

#pesquisa_filtroFiltroImagem_ServicoContratado{
  width: 24px;
  height: 24px;
}

.pesquisa_filtroCampos_ServicoContratado{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pesquisa_filtroCampoDuplo_ServicoContratado{
  display: flex;
  flex-grow: 1;
}

#pesquisa_filtroRecolher_ServicoContratado{
  margin-top: 10px;
  margin-bottom: 10px;
}

#pesquisa_filtroRecolherView_ServicoContratado{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#pesquisa_filtroRecolherImagem_ServicoContratado{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolherTexto_ServicoContratado{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#pesquisa_servicos_ServicoContratado {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  margin: 0 20px;
}

#pesquisa_paginacao_ServicoContratado {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
}

#pesquisa_paginacao_ServicoContratado {
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pesquisa_paginacao_ServicoContratado p {
  margin: 0;
  font-size: 12px;
}

#pesquisa_paginacaoBox_ServicoContratado {
  width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pesquisa_paginacaoBotao_ServicoContratado {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-primary);
  cursor: pointer;
}

.pesquisa_paginacaoBotao_ServicoContratado svg {
  filter: brightness(0) saturate(100%) invert(26%) sepia(19%) saturate(7395%) hue-rotate(234deg) brightness(89%) contrast(92%);
}

.pesquisa_paginacaoBotao_ServicoContratado:disabled {
  border: 1px solid #c2c2c2;
  cursor: unset;
}

.pesquisa_paginacaoBotao_ServicoContratado:disabled svg {
  filter: none;
}

.pesquisa_flipHorizontal_ServicoContratado {
  transform: rotate(180deg);
}

.pesquisa_servico_ServicoContratado {  
  border-left: 7px rgb(2, 155, 222) solid;
  background-color: rgb(241, 242, 242);

  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}

.pesquisa_servicoIdentificacao_ServicoContratado {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.pesquisa_servicoUsuario_ServicoContratado {
  display: flex;
  flex-grow: 1;
}

.pesquisa_servicoUsuarioTexto_ServicoContratado {
  color: rgb(195, 214, 0);
  font-weight: 600;
  font-size: 18px;
}

.pesquisa_containerAvaliacao_ServicoContratado {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pesquisa_estrelas_ServicoContratado {
  width: 24px;
  height: 24px;
}

.pesquisa_containerAvaliarAvaliado_ServicoContratado{
  display: flex;  
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pesquisa_avaliarAvaliado_ServicoContratado {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px rgb(2, 155, 222) solid;
  border-radius: 8px;

  margin-top: 5px;
  margin-bottom: 5px;

  width: 150px;
  height: 100%;  
}

.pesquisa_avaliarAvaliado_ServicoContratado label {
  color: rgb(195, 214, 0);
  font-weight: 600;
  font-size: 14px;  
}

.pesquisa_containerDescricaoServico_ServicoContratado {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.pesquisa_outrasInformacoes_ServicoContratado {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.pesquisa_avaliacao_ServicoContratado {
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: flex-end;
  min-width: 120px;
  margin-left: 10px;
}

#pesquisa_sem_ServicoContratado {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;  
}

#pesquisa_sem_ServicoContratado label{
  text-align: center;
  font-weight: 600;
  font-size: 20px;  
  color: rgb(195, 214, 0);
}

#pesquisa_botoes_ServicoContratado {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pesquisa_botao_ServicoContratado {
  width: 41px;
  height: 41px;
}

/* ================================= */
/* ===        Cadastro           === */
/* ================================= */
#dados_container_ServicoContratado{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

#dados_addTitulo_ServicoContratado {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

#dados_addTituloServico_ServicoContratado {  
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 300;
  color: rgb(2, 155, 222);
  margin-top: 6px;
  margin-right: 3px;  
}

#dados_addTituloContratante_ServicoContratado {
  font-family: "Roboto-Regular";  
  font-size: 20px;
  font-weight: 700;
  color: rgb(195, 214, 0);
}

#dados_addTraco_ServicoContratado {
  display: flex;
  justify-content: center;
}

#dados_addTituloTraco_ServicoContratado {
  width: 100px;
  border: rgb(2, 155, 222);
  height: 3px;
  background-color: rgb(2, 155, 222);
}

.dados_containerTitulo_ServicoContratado {
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: left;
  width: 90px;  
}

.dados_containerTituloTexto_ServicoContratado {  
  font-family: "Roboto-Regular";
  color: rgb(2, 155, 222);
  font-weight: 600;
}

.dados_containerCampo_ServicoContratado {
  display: flex;
  flex-grow: 1; 
}

.dados_containerTituloDuplo_ServicoContratado {
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: left;
  margin-left: 10px;
  width: 90px;  
}

.dados_addColumnsAvaliacao_ServicoContratado {
  display: flex;  
  justify-content: center;
  align-content: center;

  margin: 20px 0 0 0;
}

.dados_addColumnsAvaliacao_ServicoContratado label {
  font-family: "Roboto-Regular";
  color: rgb(2, 155, 222);
  font-weight: 600;
  font-size: 16px;
}

.dados_addColumnsEstrela_ServicoContratado {
  display: flex;  
  justify-content: center;
  align-content: center;

  margin-bottom: 10px;
}

.dados_estrela_ServicoContratado {
  width: 24px;
  height: 24px;
}

#dados_containerBotoes_ServicoContratado {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

#dados_botoes_ServicoContratado {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 10px;
}

.dados_botao_ServicoContratado {
  width: 51px;
  height: 51px;
}

@media all 
  and (max-device-width: 419px){

  .dados_addColumns_ServicoContratado {
    display: flex;
    flex-direction: row;
    margin: 10px;
  }
}

@media all 
  and (min-device-width: 420px){

  .dados_addColumns_ServicoContratado {
    display: flex;
    flex-direction: row;
    margin: 10px 20% 10px 20%;
  }
}

@media all 
  and (max-device-width: 420px){

  #titulo_ServicoContratado {
    font-family: "Roboto-Regular";
    font-size: 10px;
    font-weight: 300;  
    color: rgb(2, 155, 222);  
    margin-top: 6px;
    margin-right: 3px;  
  }
  
  #tituloSub_ServicoContratado {
    font-family: "Roboto-Regular";
    font-size: 15px;
    font-weight: 700;
    color: rgb(195, 214, 0);  
  }

  .pesquisa_filtroCampo_ServicoContratado{
    display: flex;
    flex-direction: column;
  }

  .pesquisa_usuario_ServicoContratado {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    font-size: 24px;
    font-weight: 600;
    color: rgb(195, 214, 0);  
  }  

  .pesquisa_tituloServico_ServicoContratado {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    font-size: 16px;
    font-weight: 600;

    margin-top: 5px;
  }

  .pesquisa_valor_ServicoContratado {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
  }  
}

@media all 
  and (min-device-width: 421px){

  #titulo_ServicoContratado {
    font-family: "Roboto-Regular";
    font-size: 15px;
    font-weight: 300;  
    color: rgb(2, 155, 222);  
    margin-top: 6px;
    margin-right: 3px;  
  }
  
  #tituloSub_ServicoContratado {
    font-family: "Roboto-Regular";
    font-size: 20px;
    font-weight: 700;
    color: rgb(195, 214, 0);  
  }    

  .pesquisa_filtroCampo_ServicoContratado{
    display: flex;
    flex-direction: row;
  }

  .pesquisa_usuario_ServicoContratado {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    font-size: 24px;
    font-weight: 600;
    color: rgb(195, 214, 0);  
  }  

  .pesquisa_tituloServico_ServicoContratado {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    font-size: 16px;
    font-weight: 600;
  }  

  .pesquisa_valor_ServicoContratado {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }  
}