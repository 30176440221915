#main_Usuario {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#cabecalho_Usuario{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

#cabecalhoMenu_Usuario{
  display: flex;
  flex-grow: 0;
}

#cabecalhoTitulo_Usuario{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 2px;
  justify-content: center;
}

#titulo_Usuario{
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 600;  
  color: rgb(2, 155, 222);  
  margin-top: 6px;
  margin-right: 3px;  
}

#tituloSub_Usuario{
  font-family: "Roboto-Regular";
  font-size: 20px;
  font-weight: 700;
  color: rgb(195, 214, 0);  
}

#cabecalhoLogo_Usuario{
  display: flex;
  flex-grow: 0;
}

#traco_Usuario {
  background-color: rgb(195, 214, 0);
  height: 20px;
}

#containerCarregando_Usuario {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#textCarregando_Usuario {
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 15; 
  font-weight: 800;
  color: rgb(195, 214, 0);
  margin-top: 5px;
  margin-bottom: 10px;
}

#container_Usuario {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

#containerFoto_Usuario {
  display: flex;
  justify-content: center;
}

.div_Usuario {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 5px;
}

.grow0_Usuario {
  display: flex;
  flex-grow: 0;
}

.grow0_espaco_Usuario {
  display: flex;
  flex-grow: 0;
  margin-left: 5px;
}

.grow1_Usuario {
  display: flex;
  flex-grow: 1;
}

.grow1_espaco_Usuario {
  display: flex;
  flex-grow: 1;
  margin-left: 5px;
}

#campoEstado_Usuario {
  display: flex;
  flex-grow: 1;
  padding-left: 5px;
  width: 80px;
}

#campoTelefone_Usuario {
  display: flex;
  flex-grow: 0;
  padding-left: 5px;
  width: 130px;
}

#campoWhatsApp_Usuario {
  display: flex;
  flex-grow: 0;
  padding-left: 5px;
  width: 120px;
}

#campoNumero_Usuario {
  display: flex;
  flex-grow: 0;
  width: 180px;
}

#mensagem_Usuario{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;  
  margin-top: 10px;
}

#menssageError_Usuario{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 800;
  color: red;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;  
}

#botoes_Usuario {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.containerBotao_Usuario {
  margin-right: 5px;
}

.botao_Usuario {
  height: 52px;
  width: 52px;
}

.marginLeft5_Usuario{
  margin-left: 5px;
}

@media all 
  and (max-device-width: 359px){

  #imgTitulo_Usuario {
    width: auto;
    height: 15px;
  };
}

@media all 
  and (min-device-width: 360px){

  #imgTitulo_Usuario {
    width: auto;
    height: 25px;
  };
}