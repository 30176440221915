#main_RecuperarSenha {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

#containerLogo_RecuperarSenha {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 10px;
}

#containerCarregando_RecuperarSenha {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#textCarregando_RecuperarSenha {
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 5px;
  margin-bottom: 10px;
}

#containerTitulo_RecuperarSenha {
  display: flex;
  flex-grow: 0;
  justify-content: center;
}

#cabecalhoTitulo_RecuperarSenha{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 2px;
  justify-content: center;
}

#titulo_RecuperarSenha{
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 600;  
  color: rgb(2, 155, 222);  
  margin-top: 6px;
  margin-right: 3px;  
}

#tituloSub_RecuperarSenha{
  font-family: "Roboto-Regular";
  font-size: 20px;
  font-weight: 700;
  color: rgb(195, 214, 0);  
}

#containerCampos_RecuperarSenha {
  max-width: 350px;
}

.campo_RecuperarSenha {
  display: flex;
  flex-grow: 1;
  margin-left: 20px;
  margin-right: 20px;
}

#mensagem_RecuperarSenha{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;  
}

#menssageError_RecuperarSenha{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 800;
  color: red;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;  
}

.containerBotao_RecuperaSenha {
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 3px;
  border-style: solid;
  border-color: rgb(2, 155, 222);
  border-radius: 10px;
  background-color: rgb(195, 214, 0);
  margin-left: 20px;
  margin-right: 20px;  
  height: 46px;
}

.textBotao_RecuperaSenha {
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 800;
  color: white;
}

.marginTop10_RecuperaSenha {
  margin-top: 10px;
}

.marginBottom10_RecuperaSenha {
  margin-bottom: 10px;
}

@media all 
  and (max-device-width: 260px){

  #imgLogo_RecuperarSenha {
    width: auto;
    height: 100px;
    background-color: black
  };
}

@media all 
  and (min-device-width: 261px)
  and (max-device-width: 599px){

  #imgLogo_RecuperarSenha {
    width: auto;
    height: 150px;
  };
}

@media all 
  and (min-device-width: 600px){

  #imgLogo_RecuperarSenha {
    width: auto;
    height: 200px;
  };
}