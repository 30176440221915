#main_Servico{
  display: flex;  
  flex-grow: 1;
  flex-direction: column;
}

#cabecalho_Servico{
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

#cabecalhoMenu_Servico{
  display: flex;
  flex-grow: 0;
}

#cabecalhoTitulo_Servico{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 2px;
  justify-content: center;
}

#cabecalhoLogo_Servico{
  display: flex;
  flex-grow: 0;
}

.marginTop10_Servico{
  margin-top: 10px;
}

.marginBottom10_Servico{
  margin-bottom: 10px;
}

.marginLeft5_Servico{
  margin-left: 5px;
}

/* 1 - Pesquisa */
.pesquisa_main_Servico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#pesquisa_carregando_Servico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#pesquisa_carregandoTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#pesquisa_mensagem_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 10px;
}

#pesquisa_mensagemTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#pesquisa_mensagemErro_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 10px;
  padding: 0 10px;
}

#pesquisa_mensagemErroTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#pesquisa_filtroRecolhido_Servico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#pesquisa_filtroRecolhidoFiltro_Servico{
  display: flex;
  flex-grow: 0;
  width: 35px;
}

#pesquisa_filtroRecolhidoFiltroImagem_Servico{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolhidoDescricao_Servico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#pesquisa_filtroRecolhidoDescricaoImagem_Servico{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolhidoDescricaoTexto_Servico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#pesquisa_filtro_Servico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#pesquisa_filtroFiltro_Servico{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;
}

#pesquisa_filtroFiltroImagem_Servico{
  width: 24px;
  height: 24px;
}

.pesquisa_filtroCampos_Servico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: 0;
}

.pesquisa_filtroCampo_Servico{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin-top: 10px;
}

#pesquisa_filtroRecolher_Servico{
  margin-bottom: 10px;
}

#pesquisa_filtroRecolherView_Servico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#pesquisa_filtroRecolherImagem_Servico{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolherTexto_Servico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#pesquisa_servicos_Servico {
  margin: 20px 20px 0 20px;
}

#pesquisa_sem_Servico{
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;  
  font-family: "Roboto-Regular";
  font-weight: 600;
  font-size: 20px;
  color: rgb(195, 214, 0);
  margin-bottom: 10px;
}

#pesquisa_semTexto_Servico{
  text-align: center;
}

.pesquisa_servico_Servico{  
  border-left: 7px rgb(2, 155, 222) solid;
  background-color: rgb(241, 242, 242);
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}

.pesquisa_categoria_Servico{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Roboto-Regular";
  font-size: 24px;
  font-weight: 600;
  color: rgb(195, 214, 0);  
}

.pesquisa_categoriaDescricao_Servico{
  display: flex;
  flex-grow: 1;
}

.pesquisa_categoriaExcluir_Servico{
  display: flex;
  flex-grow: 0;
}

.pesquisa_categoriaExcluirBotao_Servico{
  height: 32px;
  width: 32px;
}

.pesquisa_subCategoria_Servico{
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: 600;
  color: rgb(195, 214, 0);
}

.pesquisa_titulo_Servico{
  margin: 10px 0 10px 0;
}

.pesquisa_tituloServico_Servico{
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: 600;
}

#pesquisa_botoes_Servico{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pesquisa_botao_Servico{
  margin-right: 5px;
  width: 51px;
  height: 51px;
}

/* 2 - Categoria */
.categoria_main_Servico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#categoria_carregando_Servico{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#categoria_carregandoTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#categoria_mensagem_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#categoria_mensagemTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#categoria_mensagemErro_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#categoria_mensagemErroTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#categoria_filtroRecolhido_Servico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#categoria_filtroRecolhidoFiltro_Servico{
  display: flex;
  flex-grow: 0;
  width: 35px;
}

#categoria_filtroRecolhidoFiltroImagem_Servico{
  width: 24px;
  height: 24px;
}

#categoria_filtroRecolhidoDescricao_Servico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#categoria_filtroRecolhidoDescricaoImagem_Servico{
  width: 24px;
  height: 24px;
}

#categoria_filtroRecolhidoDescricaoTexto_Servico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#categoria_filtro_Servico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#categoria_filtroFiltro_Servico{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;
}

#categoria_filtroFiltroImagem_Servico{
  width: 24px;
  height: 24px;
}

#categoria_filtroCampos_Servico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#categoria_filtroCampo_Servico{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

#categoria_filtroRecolher_Servico{
  margin-bottom: 10px;
}

#categoria_filtroRecolherView_Servico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#categoria_filtroRecolherImagem_Servico{
  width: 24px;
  height: 24px;
}

#categoria_filtroRecolherTexto_Servico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#categoria_titulo_Servico{
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

#categoria_dados_Servico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

#catetoria_card_Servico{
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

#categoria_dadosSem_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 264px;
}

#categoria_dadosSemTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 600;
  color: rgb(195, 214, 0);
  text-align: center;
}

#categoria_botoes_Servico{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.categoria_botao_Servico{
  margin-right: 5px;
  width: 51px;
  height: 51px;
}

/* 3 - Sub-Categoria */
.subCategoria_main_Servico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#subCategoria_carregando_Servico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;  
}

#subCategoria_carregandoTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_mensagem_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#subCategoria_mensagemTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_mensagemErro_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#subCategoria_mensagemErroTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_filtroRecolhido_Servico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#subCategoria_filtroRecolhidoFiltro_Servico{
  display: flex;
  flex-grow: 0;  
}

#subCategoria_filtroRecolhidoFiltroImagem_Servico{
  width: 24px;
  height: 24px;
}

#subCategoria_filtroRecolhidoDescricao_Servico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#subCategoria_filtroRecolhidoDescricaoImagem_Servico{
  width: 24px;
  height: 24px;
}

#subCategoria_filtroRecolhidoDescricaoTexto_Servico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#subCategoria_filtro_Servico{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#subCategoria_filtroFiltro_Servico{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;
}

#subCategoria_filtroFiltroImagem_Servico{
  width: 24px;
  height: 24px;
}

#subCategoria_filtroCampos_Servico{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#subCategoria_filtroCampo_Servico{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

#subCategoria_filtroRecolher_Servico{
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_filtroRecolherView_Servico{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#subCategoria_filtroRecolherImagem_Servico{
  width: 24px;
  height: 24px;
}

#subCategoria_filtroRecolherTexto_Servico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#subCategoria_titulo_Servico{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#subCategoria_dados_Servico{
  display: flex;
  flex-grow: 1;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}

#subCategoria_dadosSem_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 264px;
}

#subCategoria_dadosSemTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 600;
  color: rgb(195, 214, 0);
  text-align: center;
}

/* 4 - Cadastro */
.cadastro_main_Servico{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 10px;
}

.cadastro_containerCampo_Servico{
  display: flex;
  flex-grow: 1; 
}

#cadastro_mensagem_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 10px;
}

#cadastro_mensagemTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#cadastro_mensagemErro_Servico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 10px;
}

#cadastro_mensagemErroTexto_Servico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#cadastro_botoes_Servico{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cadastro_botao_Servico{
  margin-right: 5px;
  width: 51px;
  height: 51px;
}

@media all 
  and (max-device-width: 419px){

  #titulo_Servico{
    font-family: "Roboto-Regular";
    font-size: 10px;
    font-weight: 300;  
    color: rgb(2, 155, 222);  
    margin-top: 6px;
    margin-right: 3px;  
  }
  
  #tituloSub_Servico{
    font-family: "Roboto-Regular";
    font-size: 15px;
    font-weight: 700;
    color: rgb(195, 214, 0);  
  }

  .cadastro_addColumns_Servico {
    display: flex;
    flex-direction: row;
    margin: 0px 10px 0px 10px;
  }
}

@media all 
  and (min-device-width: 420px){

  #titulo_Servico{
    font-family: "Roboto-Regular";
    font-size: 15px;
    font-weight: 300;  
    color: rgb(2, 155, 222);  
    margin-top: 6px;
    margin-right: 3px;  
  }
  
  #tituloSub_Servico{
    font-family: "Roboto-Regular";
    font-size: 20px;
    font-weight: 700;
    color: rgb(195, 214, 0);  
  }

  .cadastro_addColumns_Servico {
    display: flex;
    flex-direction: row;
    margin: 0px 20% 0px 20%;
  }
}