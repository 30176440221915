.main_ServicoPrestado{
  display: flex;
  flex-grow: 1;
  flex-basis: 1;
  flex-direction: column;
}

#carregando_ServicoPrestado{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#carregandoTexto_ServicoPrestado{
  text-align: center;
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#cabecalho_ServicoPrestado{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;  
}

#cabecalhoMenu_ServicoPrestado{
  display: flex;
  flex-grow: 0;
}

#cabecalhoTitulo_ServicoPrestado{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 2px;
  justify-content: center;
}

#cabecalhoLogo_ServicoPrestado{
  display: flex;
  flex-grow: 0;
}

#bordarBottom_ServicoPrestado{
  border-bottom: solid 10px rgb(195, 214, 0);
}

#mensagem_ServicoPrestado{
  display: flex;
  flex-grow: 0;  
  justify-content: center;
  align-items: center;
}

#mensagemTexto_ServicoPrestado{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#mensagemErro_ServicoPrestado{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
}

#mensagemErroTexto_ServicoPrestado{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

.marginTop5_ServicoPrestado{
  margin-top: 5px;
}

.marginTop10_ServicoPrestado{
  margin-top: 10px;
}

.marginLeft5_ServicoPrestado{
  margin-left: 5px;
}

/* ================================= */
/* ===          Contratante      === */
/* ================================= */
#pesquisa_filtroRecolhido_ServicoPrestado{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

#pesquisa_filtroRecolhidoFiltro_ServicoPrestado{
  display: flex;
  flex-grow: 0;
  align-items: center;
}

#pesquisa_filtroRecolhidoFiltroImagem_ServicoPrestado{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolhidoDescricao_ServicoPrestado{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#pesquisa_filtroRecolhidoDescricaoImagem_ServicoPrestado{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolhidoDescricaoTexto_ServicoPrestado{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#pesquisa_filtro_ServicoPrestado{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  background-color: rgb(195, 214, 0);
  padding-left: 10px;
  padding-right: 10px;
}

#pesquisa_filtroFiltro_ServicoPrestado{
  display: flex;
  flex-grow: 0;
  width: 35px;
  margin-top: 15px;
}

#pesquisa_filtroFiltroImagem_ServicoPrestado{
  width: 24px;
  height: 24px;
}

.pesquisa_filtroCampos_ServicoPrestado{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pesquisa_filtroCampoDuplo_ServicoPrestado{
  display: flex;
  flex-grow: 1;
}

#pesquisa_filtroRecolher_ServicoPrestado{
  margin-top: 10px;
  margin-bottom: 10px;
}

#pesquisa_filtroRecolherView_ServicoPrestado{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
}

#pesquisa_filtroRecolherImagem_ServicoPrestado{
  width: 24px;
  height: 24px;
}

#pesquisa_filtroRecolherTexto_ServicoPrestado{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(2, 155, 222);
  margin-top: 2px;
  padding-left: 10px;
}

#pesquisa_servicos_ServicoPrestado{
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  margin: 0 20px;
}

#pesquisa_paginacao_ServicoPrestado{
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
}

#pesquisa_paginacao_ServicoPrestado{
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pesquisa_paginacao_ServicoPrestado p{
  margin: 0;
  font-size: 12px;
}

#pesquisa_paginacaoBox_ServicoPrestado{
  width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pesquisa_paginacaoBotao_ServicoPrestado{
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-primary);
  cursor: pointer;
}

.pesquisa_paginacaoBotao_ServicoPrestado svg{
  filter: brightness(0) saturate(100%) invert(26%) sepia(19%) saturate(7395%) hue-rotate(234deg) brightness(89%) contrast(92%);
}

.pesquisa_paginacaoBotao_ServicoPrestado:disabled{
  border: 1px solid #c2c2c2;
  cursor: unset;
}

.pesquisa_paginacaoBotao_ServicoPrestado:disabled svg{
  filter: none;
}

.pesquisa_flipHorizontal_ServicoPrestado{
  transform: rotate(180deg);
}

#pesquisa_sem_ServicoPrestado {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;  
  font-weight: 600;
  font-size: 20px;  
  color: rgb(195, 214, 0);
}

#pesquisa_botoes_ServicoPrestado {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pesquisa_botao_ServicoPrestado {
  width: 41px;
  height: 41px;
}

.pesquisa_filtroInputContratante_ServicoPrestado {
  border: 3px solid rgb(195, 214, 0);
  border-radius: 50px;
  width: 100%;
    
  background-color: white;
  color: rgb(2, 155, 222);
}

.pesquisa_servico_ServicoPrestado {  
  border-left: 7px rgb(2, 155, 222) solid;
  background-color: rgb(241, 242, 242);

  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}

.pesquisa_servicoIdentificacao_ServicoPrestado {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.pesquisa_servicoUsuario_ServicoPrestado {
  display: flex;
  flex-grow: 1;
}

.pesquisa_servicoUsuarioTexto_ServicoPrestado {
  color: rgb(195, 214, 0);
  font-weight: 600;
  font-size: 18px;
}

.pesquisa_containerAvaliacao_ServicoPrestado {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pesquisa_avaliacao_ServicoPrestado {
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: flex-end;
  min-width: 120px;
  margin-left: 10px;
}

.pesquisa_naoAvaliado_ServicoPrestado {
  color: black;
  font-size: 16px;
  font-weight: 400;
}

.pesquisa_avaliado_ServicoPrestado {
  min-width: 450px;
}

.pesquisa_estrelas_ServicoPrestado {
  width: 24px;
  height: 24px;
}

.pesquisa_avaliarAvaliado_ServicoPrestado label {
  display: flex;
  justify-content: center;

  color: rgb(195, 214, 0);
  font-weight: 600;
  font-size: 14px;  
}

.pesquisa_containerBotaoExcluir_ServicoPrestado {
  display: flex;
  flex-grow: 0;
  align-items: center;
}

.pesquisa_containerExcluir_ServicoPrestado {
  display: flex;
  flex-grow: 0;
  align-items: center;
  margin-left: 10px;
}

#excluir_ServicoPrestado {
  height: 32px;
  width: 32px;
}

.pesquisa_descricao_ServicoPrestado {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pesquisa_containerDescricaoServico_ServicoPrestado {
  display: flex;
  flex-grow: 1;
}

.pesquisa_descricaoServico_ServicoPrestado {
  color: black;
  font-size: 16px;
  font-weight: 600;
}

.pesquisa_outrasInformacoes_ServicoPrestado {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

/* ================================= */
/* ===        Cadastro           === */
/* ================================= */
#dados_container_ServicoPrestado{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}

#dados_addTitulo_ServicoPrestado{
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

#dados_addTraco_ServicoPrestado{
  display: flex;
  justify-content: center;
}

#dados_addTituloTraco_ServicoPrestado{
  width: 100px;
  border: rgb(2, 155, 222);
  height: 3px;
  background-color: rgb(2, 155, 222);
}

.dados_telas_ServicoPrestado{
  display: flex;
  flex-direction: column;
}

.dados_containerCampos_ServicoPrestado {
  display: flex;
  flex-grow: 1;
  margin: 0 20px;
}

.dados_containerCampo_ServicoPrestado {
  display: flex;
  flex-direction: row;
  flex-grow: 1; 
  margin-top: 10px;
}

#dados_botoes_ServicoPrestado {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  margin-bottom: 10px;
}

.dados_botao_ServicoPrestado {
  width: 51px;
  height: 51px;
}
























#servico-Prestado {
  display: flex;
  flex-direction: column;
  
  /* min-height: 657px; */
  min-height: 100vh;
  
  height: 100%;
  width: 100%;
}

#servico-prestado-cabecalho {
  display: flex;
  justify-content: space-between;

  margin: 0 10px 0 10px;
}

#servico-prestado-cabecalho-titulo {
  margin-top: 10px;
  text-align: center;
}

#servico-prestado-corpo { 
  flex: 1;

  width: 100%;
  height: 100%;
}

#servico-prestado-filtro {
  display: flex;
  flex-direction: row;

  background-color: rgb(195, 214, 0);
  padding: 10px;
}

#servico-prestado-filtro img {    
  margin: 9px 10px 0 5px;
}

#servico-prestado-paginacao {
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#servico-prestado-paginacao p {
  margin: 0;
  font-size: 12px;
}

#servico-prestado-paginacao-box {
  width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.servico-prestado-paginacao-botao {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-primary);
  cursor: pointer;
}

.servico-prestado-paginacao-botao svg {
  filter: brightness(0) saturate(100%) invert(26%) sepia(19%) saturate(7395%) hue-rotate(234deg) brightness(89%) contrast(92%);
}

.servico-prestado-paginacao-botao:disabled {
  border: 1px solid #c2c2c2;
  cursor: unset;
}

.servico-prestado-paginacao-botao:disabled svg {
  filter: none;
}

.servico-prestado-flip-horizontal {
  transform: rotate(180deg);
}

/* ================================= */
/* ===          Prestador        === */
/* ================================= */

.servico-prestado-filtro-input-prestador {
  border: 3px solid rgb(195, 214, 0);
  border-radius: 50px;
  width: 100%;
    
  background-color: white;
  color: rgb(2, 155, 222);
}

#servico-prestado-servicos {
  margin: 20px 20px 0 20px;
}

.servico-prestado-servico {  
  border-left: 7px rgb(2, 155, 222) solid;
  background-color: rgb(241, 242, 242);

  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}

.servico-prestado-usuario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 24px;
  font-weight: 600;
  color: rgb(195, 214, 0);  
}

.servico-prestado-nao-avaliado {
  color: black;

  font-size: 16px;
  font-weight: 400;  
}

.servico-prestado-titulo-servico {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 600;
}

.servico-prestado-excluir {
  margin-bottom: 10px;
}

.servico-prestado-valor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#servico-prestado-mensagem {
  margin: 20px 10px 0 10px;
}

#servico-prestado-botoes {
  text-align: center;
}

.servico-prestado-botao {
  margin-right: 5px;
}

#servico-prestado-rodape {
  background-color: rgb(195, 214, 0);
  text-align: center;

  margin-top: 10px;
  padding: 7px;

  color: white;
}

#servico-prestado-sem {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  color: rgb(195, 214, 0);
  font-weight: 600;
  font-size: 20px;
}

/* ================================= */
/* ===        Cadastro           === */
/* ================================= */

#servico-prestado-add-titulo {
  display: flex;
  justify-content: center;

  margin-top: 30px;
}

#servico-prestado-add-titulo-servico {  
  margin-top: 6px;
  margin-right: 3px;
  color: rgb(2, 155, 222);

  font-size: 15px;
  font-weight: 300;  
}

#servico-prestado-add-titulo-prestado {  
  color: rgb(195, 214, 0);
  font-size: 20px;
  font-weight: 700;
}

#servico-prestado-add-traco {
  display: flex;
  justify-content: center;

  width: 100%;
}

#servico-prestado-add-titulo-traco {
  width: 100px;

  border: rgb(2, 155, 222);
  height: 3px;
  background-color: rgb(2, 155, 222);
}

.servico-prestado-add-columns {
  display: flex;
  flex-direction: row;

  margin: 10px 20px 10px 20px;
}

.servico-prestado-add-input {
  border: 3px solid rgb(195, 214, 0);
  border-radius: 50px;
  width: auto;

  color: rgb(2, 155, 222);
}

.servico-prestado-add-margin-right-5 {
  margin-right: 5px;
}

.servico-prestado-add-margin-top-10 {
  margin-top: 10px;
}

#servico-prestado-add-mensagem {
  margin: 20px 20px 0 20px;
}

@media all 
  and (max-device-width: 420px){

  #titulo_ServicoPrestado{
    font-family: "Roboto-Regular";
    font-size: 10px;
    font-weight: 300;  
    color: rgb(2, 155, 222);
    margin-top: 6px;
    margin-right: 3px;  
  }

  #tituloSub_ServicoPrestado{
    font-family: "Roboto-Regular";
    font-size: 15px;
    font-weight: 700;
    color: rgb(195, 214, 0);  
  }

  #dados_addTituloServico_ServicoPrestado{
    font-family: "Roboto-Regular";
    font-size: 10px;
    font-weight: 300;
    color: rgb(2, 155, 222);
    margin-top: 6px;
    margin-right: 3px;  
  }
  
  #dados_addTituloContratante_ServicoPrestado{
    font-family: "Roboto-Regular";  
    font-size: 15px;
    font-weight: 700;
    color: rgb(195, 214, 0);
  }  
}

@media all 
  and (min-device-width: 421px){

  #titulo_ServicoPrestado{
    font-family: "Roboto-Regular";
    font-size: 15px;
    font-weight: 300;  
    color: rgb(2, 155, 222);  
    margin-top: 6px;
    margin-right: 3px;  
  }

  #tituloSub_ServicoPrestado{
    font-family: "Roboto-Regular";
    font-size: 20px;
    font-weight: 700;
    color: rgb(195, 214, 0);  
  }  

  #dados_addTituloServico_ServicoPrestado{
    font-family: "Roboto-Regular";
    font-size: 15px;
    font-weight: 300;
    color: rgb(2, 155, 222);
    margin-top: 6px;
    margin-right: 3px;  
  }
  
  #dados_addTituloContratante_ServicoPrestado{
    font-family: "Roboto-Regular";  
    font-size: 20px;
    font-weight: 700;
    color: rgb(195, 214, 0);
  }  
}