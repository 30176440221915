#main_InputMasked{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#titulo_InputMasked{
  font-size: 14px;
  font-Weight: 800;
  color: rgb(2, 155, 222);
  margin-left: 10px;
}

#container_InputMasked{
  display: flex;
  flex-direction: row;
  border-width: 3px;
  border-style: solid;
  border-color: rgb(195, 214, 0);
  border-radius: 10px;  
  height: 45px;
  margin-bottom: 10px;
}

.viewCampo_InputMasked{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.imagem_InputMasked{
  display: flex;
  flex-grow: 0;
  width: 50px;
}

#imagemCep_InputMasked{
  display: flex;
  flex-grow: 0;
  width: 70px;
}

.campo_InputMasked{
  display: flex;
  flex-grow: 1;
}

.botao_InputMasked{
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 5px;
  height: 28px;
  width: 28px;  
}

#botaoLupa_InputMasked{
  margin-top: 5px;
  margin-right: 5px;
  height: 28px;
  width: 28px;  
}

#botaoExclusion_InputMasked{
  margin-top: 5px;
  height: 28px;
  width: 28px;  
}

.campo_InputMasked{
  display: flex;
  flex-grow: 1;
}

.class_InputMasked{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  border: solid 1px white;
  border-radius: 10px;
  outline: none;
  color: rgb(2, 155, 222);
  padding-left: 10px;
  height: 100%;
  width: 100%;
}

.class_InputMasked::placeholder{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  color: rgb(2, 155, 222);
}