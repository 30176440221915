#main_EnlargePhoto{
  display: flex;  
  flex-grow: 1;
  flex-direction: column;
  margin: 10px;  

  align-items: center;
  justify-content: center;
}

.photo_EnlargePhoto {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.closePhoto_EnlargePhoto{
  height: 36px;
  width: 36px;
}