#main_InputNumeric{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#titulo_InputNumeric{
  font-size: 14px;
  font-Weight: 800;
  color: rgb(2, 155, 222);
  margin-left: 10px;
}

#container_InputNumeric{
  display: flex;
  flex-direction: row;
  border-width: 3px;
  border-style: solid;
  border-color: rgb(195, 214, 0);
  border-radius: 10px;  
  height: 45px;
  margin-bottom: 10px;
}

.viewCampo_InputNumeric{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

.imagem_InputNumeric{
  display: flex;
  flex-grow: 0;
  width: 50px;
}

.campo_InputNumeric{
  display: flex;
  flex-grow: 1;
}

.botao_InputNumeric{
  margin-top: 3px;
  margin-left: 15px;
  margin-right: 5px;
}

.campo_InputNumeric{
  display: flex;
  flex-grow: 1;
}

.class_InputNumeric{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  border: solid 1px white;
  border-radius: 10px;
  outline: none;
  color: rgb(2, 155, 222);
  padding-left: 10px;
  height: 100%;
  width: 100%;
}

.class_InputNumeric::placeholder{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  color: rgb(2, 155, 222);
}













#viewInputNumericZero{
  flex-grow: 0;
}

#viewInputNumericUm{
  flex-grow: 1;
}

#viewTitleInputNumeric{
  min-width: 125px;
  max-width: 125px;
}

#titleNumeric{
  font-size: 16px;
  font-weight: 800;
  color: rgb(2, 155, 222);
  margin-left: 10px;
  margin-bottom: 5px;
}

#inputNumeric{
  flex-direction: "row";
  border-width: 3px;
  border-style: solid;
  border-color: rgb(195, 214, 0);
  border-radius: 10px;
  color: rgb(2, 155, 222);
  margin-bottom: 10px;
  padding-left: 10px;
  height: 45px;
}

#inputNumericImg{
  margin-top: 3px;
  margin-left: 15px;
  margin-right: 5px;
}

.inputNumericTextUm{
  flex-grow: 1;
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15;
  color: rgb(2, 155, 222);
}