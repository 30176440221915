#main_Login{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

#containerLogo_Login{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.mensagem_Login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;  
}

#mensagemLabel_Login{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 800;
  color: rgb(195, 214, 0);
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;  
}

#menssageError_Login{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 800;
  color: red;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;  
}

.view_Login{
  padding-left: 20px;
  padding-right: 20px;
}

#botao_Login{
  border-width: 3px;
  border-style: solid;
  border-color: rgb(2, 155, 222);
  border-radius: 10px;
  background-color: rgb(195, 214, 0);
  margin-left: 20px;
  margin-right: 20px;  
  height: 46px;
}

#botaoDiv_Login{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

#botaoLabel_Login{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 800;
  color: white;
}

#novo_Login{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

#novoLabel_Login{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 800;
  color: rgb(2, 155, 222);
}

#esqueceu_Login{
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

#esqueceuLabel_Login{
  font-family: "Roboto-Regular";
  font-size: 15;
  font-weight: 800;
  color: rgb(2, 155, 222);
}

@media all 
  and (max-device-width: 260px){

  #imgLogo_Login {
    width: auto;
    height: 100px;
    background-color: black
  };
}

@media all 
  and (min-device-width: 261px)
  and (max-device-width: 599px){

  #imgLogo_Login {
    width: auto;
    height: 150px;
  };
}

@media all 
  and (min-device-width: 600px){

  #imgLogo_Login {
    width: auto;
    height: 200px;
  };
}

@media all 
  and (max-device-width: 280px){

  #container_Login {
    width: 250px;    
  }
}

@media all 
  and (min-device-width: 281px)
  and (max-device-width: 380px){

  #container_Login {
    width: 330px;    
  }
}

@media all 
  and (min-device-width: 381px){

  #container_Login {
    width: 350px;    
  }
}