#main_Input{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#titulo_Input{
  font-size: 14px;
  font-Weight: 800;
  color: rgb(2, 155, 222);
  margin-left: 10px;
}

#container_Input{
  display: flex;
  flex-direction: row;
  border-width: 3px;
  border-style: solid;
  border-color: rgb(195, 214, 0);
  border-radius: 10px;
  height: 45px;
  margin-bottom: 5px;
}

.viewCampo_Input{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding-right: 5px;
}

.viewCampoBusca_Input{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 0 5px;
}

.botao_Input{
  margin-top: 3px;
  margin-left: 15px;
  margin-right: 5px;
}

.imagem_Input{
  display: flex;
  flex-grow: 0;
  width: 50px;
}

.imagemBusca_Input{
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 50px;
}

.campo_Input{
  display: flex;
  flex-grow: 1;
}

.class_Input{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  border: solid 1px white;
  border-radius: 10px;
  outline: none;
  color: rgb(2, 155, 222);
  padding-left: 10px;
  height: 100%;
  width: 100%;
}

.class_Input::placeholder{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  color: rgb(2, 155, 222);
}

.classImagem_Input{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  border: none;
  outline: none;
  color: rgb(2, 155, 222);
  height: 100%;
  width: 100%;
}

.classImagem_Input::placeholder{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  color: rgb(2, 155, 222);
}

.classImagemBusca_Input{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  border: none;
  outline: none;
  color: rgb(2, 155, 222);
  height: 100%;
  width: 100%;
}

.classImagemBusca_Input::placeholder{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  color: rgb(2, 155, 222);
  text-align: center;
}

.file_Input{
  display: none;
}

#login_Input{
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#containerCamera_Input{
  margin-left: 230px;
  margin-top: -35px;
}

#camera_Input{
  height: 36px;
  width: 36px;
}

.foto_Input{
  border-radius: 10%;
  height: 100px;
  width: auto;
}