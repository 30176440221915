.main_ListaServico {
  display: flex;
  flex-grow: 1;
  flex-basis: 1;
  flex-direction: column;
}

#carregando_ListaServico{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-top: 10px;
}

#carregandoTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#campo_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 20px;
}

#descricao_ListaServico{
  display: flex;
  flex-grow: 1;
}

#limpar_ListaServico{
  display: flex;
  flex-grow: 0;
  margin-top: 5px;
  padding-left: 5px;
}

#limpar_ListaServico img{
  height: 32px;
  width: 32px;
}

#limparSelecionado_ListaServico{
  display: flex;
  flex-grow: 0;
  margin-top: 25px;
  padding-left: 5px;
}

#limparSelecionado_ListaServico img{
  height: 32px;
  width: 32px;
}

#selecione_ListaServico{
  display: flex;  
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 10px;
}

#selecioneTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(195, 214, 0);
}

#grid_ListaServico {
  margin: 20px 30px 10px 30px;
}

.resultado_ListaServico{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  background-color: rgb(241, 242, 242);
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: rgb(195, 214, 0);
  padding-left: 10px;
  margin-bottom: 10px;
}

.resultadoDescricao_ListaServico{
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.resultadoBotao_ListaServico{
  display: flex;
  flex-grow: 0;
  align-items: center;
}

.botao_ListaServico{
  height: 31px;
  width: 31px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
}

#nenhumRegistro_ListaServico{
  display: flex;  
  flex-grow: 1;
  justify-content: center;
}

#nenhumRegistroTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-weight: 600;
  color: rgb(195, 214, 0);
}

#mensagem_ListaServico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#mensagemTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}

#mensagemErro_ListaServico{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#mensagemErroTexto_ListaServico{
  font-family: "Roboto-Regular";
  font-size: 16;
  font-weight: 600;
  color: rgb(195, 214, 0);
  margin-top: 10px;
  margin-bottom: 10px;
}