#footer_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(195, 214, 0);
  padding-top: 10px;
  padding-bottom: 10px;  
  width: 100%;
}

#footerLabel_footer {
  color: white;
  text-align: center;
}