#card_Categoria {
  border: 3px rgb(195, 214, 0) solid;
  border-radius: 8px 8px 8px 8px;
  background-color: white;
  width: 165px;
  height: 165px;  
}

#cardConteudo_Categoria {
  display: flex;
  flex-grow: 1;
}

.cardImagem_Categoria {
  margin-top: 7px;
  margin-left: 5px;
  width: 150px;
  height: 150px;
}