.main_SubCategoria{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
}

#descricao_SubCategoria {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

#selecionar_SubCategoria {
  display: flex;
  flex-grow: 0;
  align-items: center;
}

.botao_SubCategoria{
  width: 51px;
  height: 51px;
}

.descricaoBotao_SubCategoria{
  color: rgb(195, 214, 0);
}

#semRegistro_SubCategoria {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

#botoes_SubCategoria {
  display: flex;
  flex-grow: 0;
  justify-content: center;
}

.botao_SubCategoria {
  margin-right: 10px;
  width: 51px;
  height: 51px;
}

@media all 
  and (max-device-width: 280px){

  #cardItem_SubCategoria {
    display: flex;
    flex-direction: row;
    flex-grow: 0;  
    border-left: 5px  rgb(195, 214, 0) solid;
    margin-bottom: 5px;
    padding-left: 10px;
    width: 250px;
  };
}

@media all 
  and (min-device-width: 281px)
  and (max-device-width: 360px){

  #cardItem_SubCategoria {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  
    border-left: 5px  rgb(195, 214, 0) solid;
    margin-bottom: 5px;
    padding-left: 10px;
    width: 330px;
  };
}

@media all 
  and (min-device-width: 361px)
  and (max-device-width: 379px){

  #cardItem_SubCategoria {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
  
    border-left: 5px  rgb(195, 214, 0) solid;
    margin-bottom: 5px;
    padding-left: 10px;
    width: 345px;
  };
}

@media all 
  and (min-device-width: 380px){

  #cardItem_SubCategoria {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 0;
  
    border-left: 5px  rgb(195, 214, 0) solid;
    margin-bottom: 5px;
    padding-left: 10px;
    width: 350px;
  };
}