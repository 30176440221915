#main_Select{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#titulo_Select{
  font-size: 14px;
  font-Weight: 800;
  color: rgb(2, 155, 222);
  margin-left: 10px;
}

#container_Select{
  display: flex;
  flex-direction: row;
  border-width: 3px;
  border-style: solid;
  border-color: rgb(195, 214, 0);
  border-radius: 10px;
  height: 45px;
  margin-bottom: 5px;
}

.campo_Select{
  display: flex;
  flex-grow: 1;
}

.class_Select{
  font-family: "Roboto-Regular";
  font-weight: 800;
  font-size: 15px;
  border: solid 1px white;
  border-radius: 10px;
  outline: none;
  color: rgb(2, 155, 222);
  height: 100%;
  width: 100%;
  padding-left: 5px;
}