#main_Menu {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#cabecalho_Menu{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

#cabecalhoMenu_Menu{
  display: flex;
  flex-grow: 0;
}

#cabecalhoTitulo_Menu{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  margin: 2px;
  justify-content: center;
}

#titulo_Menu{
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 300;  
  color: rgb(2, 155, 222);  
  margin-top: 6px;
  margin-right: 3px;  
}

#tituloSub_Menu{
  font-family: "Roboto-Regular";
  font-size: 20px;
  font-weight: 700;
  color: rgb(195, 214, 0);
}

#cabecalhoLogo_Menu{
  display: flex;
  flex-grow: 0;
}

#traco_Menu {
  background-color: rgb(195, 214, 0);
  height: 20px;
}

#perfil_Menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

#foto_Menu {
  display: flex;
  flex-grow: 0;
} 

#imgFoto_Menu {
  border-radius: 10%;
  height: 120px;
  width: auto;  
}

#informacoes_Menu {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}

.containerNome_Menu {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}

#containerBoneco_Menu {
  width: 48px;
  height: auto;
}

.containerNomeImagem_Menu {
  width: 48px;
  height: auto;
}

.containerNomeTexto_Menu {
  display: flex;
  align-items: center;
  padding-left: 5px;
}

#telas_Menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  
}

#retornar_Menu {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.bordaTitulo_Menu{
  display: flex;
  flex-grow: 0;
  border-left: 5px solid rgb(195, 214, 0);
  padding-left: 5px;
}

.tituloTela_Menu{
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: 10px;  
}

#botaoRetornar_Menu{
  height: 42px;
  width: 42px;
}

#botaoSair_Menu {
  height: 42px;
  width: 42px;
  margin-left: 5px;
}

.flexGrow0_Menu {
  display: flex;
  flex-grow: 0;
}

.botao42_Menu {
  height: 42px;
  width: 42px;
}

@media all and (max-device-width: 359px){
  #imgTitulo_Menu { width: auto; height: 15px; }
}

@media all and (min-device-width: 360px){
  #imgTitulo_Menu { width: auto; height: 25px; }
}

@media all and (max-device-width: 380px){
  #containerPerfil_Menu {
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 260px;
  }

  .containerTela_Menu {
    display: flex;
    flex-grow: 0;
    flex-direction: "row";
    margin-top: 10px;
    width: 260px;
  }
}

@media all and (min-device-width: 381px){
  #containerPerfil_Menu {
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    margin-top: 10px;
    width: 350px;
  }

  .containerTela_Menu {
    display: flex;
    flex-grow: 0;
    flex-direction: "row";
    margin-top: 10px;
    width: 350px;
  }  
}